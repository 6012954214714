import React from 'react';
import { number, shape, string, oneOfType, arrayOf, bool } from 'prop-types';

import { Pagination } from '@andes/pagination';

import { formatUrl } from './utils/helpers';
import { withComponentHandler } from '../../hocs/with-component-handler';

const namespace = 'ui-search-pagination';

/**
 * @typedef {Object} PaginationNode
 * @property {number|string} value - El valor de la página.
 * @property {string} url - La URL de la página.
 * @property {boolean} is_actual_page - Indica si es la página actual.
 */

/**
 * Componente de paginación para dispositivos móviles.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {PaginationNode[]} props.paginationNodes - Los nodos de paginación.
 * @returns {JSX.Element} El componente de paginación.
 */

export const PaginationMobile = ({ paginationNodes }) => {
  const actualNode = paginationNodes.find((node) => node.is_actual_page);
  const pageSelected = Number(actualNode.value);
  const firstNode = paginationNodes[0];
  const firstPage = Number(firstNode.value);
  const lastNode = paginationNodes[paginationNodes.length - 1];
  const lastPage = Number(lastNode.value);

  return (
    <Pagination
      className={namespace}
      pageSelected={pageSelected}
      pageQuantity={lastPage}
      formatUrl={formatUrl(paginationNodes, firstPage, lastPage)}
    />
  );
};

PaginationMobile.propTypes = {
  paginationNodes: arrayOf(
    shape({
      value: oneOfType([number, string]),
      url: string,
      is_actual_page: bool,
    }),
  ).isRequired,
};

export default withComponentHandler(PaginationMobile, { componentName: 'PaginationMobile', isCritical: true });
