import React from 'react';
import PropTypes from 'prop-types';

import { UI_SEARCH_RELATED_SEARCHES_NAMESPACE as namespace } from '../../constants';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @typedef {Object} RelatedSearch
 * @property {string} id - The unique identifier for the related search.
 * @property {string} url - The URL for the related search.
 * @property {string} q - The query string for the related search.
 */

/**
 * RelatedSearches component renders a list of related searches.
 *
 * @param {Object} props - The component props.
 * @param {RelatedSearch[]} props.related_searches - An array of related search objects.
 * @param {string} props.label - The label for the related searches group.
 * @returns {JSX.Element} The rendered component.
 */

const RelatedSearches = ({ related_searches, label }) => (
  <div className={namespace}>
    <dl className={`${namespace}__group`}>
      <dt className={`${namespace}__title`}>{label}</dt>
      {related_searches.map((related_search) => (
        <dd key={related_search.id} className={`${namespace}__item`}>
          <a className={`${namespace}__link`} href={related_search.url}>
            {related_search.q}
          </a>
        </dd>
      ))}
    </dl>
  </div>
);

RelatedSearches.propTypes = {
  label: PropTypes.string.isRequired,
  related_searches: PropTypes.array,
};

RelatedSearches.defaultProps = {
  related_searches: [],
};

export default withComponentHandler(RelatedSearches, { componentName: 'RelatedSearches-mobile' });
